import useSWR from 'swr'
import { Host } from './types'

export const useHost = (hostId?: string) => {
  // console.log('useHost', hostId)
  const { data, error } = useSWR<Host>(hostId ? `/hosts/${hostId}` : null)

  return {
    host: data,
    isHostLoading: !error && !data,
    isHostError: error,
  }
}

import styles from './AccountMenu.module.css'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  ChevronDownIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { signIn, signOut } from 'next-auth/react'
import Clickable from 'components/Clickable'
import ProfileImageWithFallback from 'components/ProfileImageWithFallback'
import { makeRedirectTokenForUser, useScreenName, useUser } from 'data/users/client'
import classNames from 'classnames'
import { useParentUrl } from 'hooks/useParentUrlRecording'

type Props = {
  suppressIfNoUser?: boolean,
  embeddedUrl?: string,
}

const getOriginFromParentUrlString = (parentUrlString: string) => {
  const parentUrl = new URL(parentUrlString)
  const origin = parentUrl.host
  return origin
}

const AccountMenu = ({ suppressIfNoUser = false, embeddedUrl }: Props) => {
  const { user } = useUser()
  const screenName = useScreenName()
  const handleSignIn = async () => {
    if (embeddedUrl) {
      const parentUrlString = useParentUrl()
      const origin = getOriginFromParentUrlString(parentUrlString)
      const redirectToken = await makeRedirectTokenForUser(parentUrlString, { commentText: '' })
      // console.log({ redirectToken })
      const redirectUrl = `https://${window.location.host}/commenting/login/${redirectToken}`
      window?.parent?.postMessage({
        blotter: {
          type: 'loginRedirect',
          redirectUrl,
        }
      }, `https://${origin}`)
    } else {
      // not embedded, so just use next-auth signin function
      signIn('twitter')
    }
  }
  const handleSignOut = async () => {
    if (embeddedUrl) {
      const parentUrlString = useParentUrl()
      const origin = getOriginFromParentUrlString(parentUrlString)
      const redirectUrl = `https://${window.location.host}/logout?redirect_url=${encodeURIComponent(parentUrlString)}`
      window?.parent?.postMessage({
        blotter: {
          type: 'loginRedirect',
          redirectUrl,
        }
      }, `https://${origin}`)
    } else {
      signOut({
        callbackUrl: '/',
      })
    }
  }
  if (suppressIfNoUser && !user) {
    return <></>
  }
  return <Menu as="div" className="ml-1 relative">
    <div>
      <Menu.Button className="max-w-xs py-2 px-1 rounded-full text-gray-400 hover:text-gray-500 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:rounded-md lg:hover:bg-gray-50 relative">
        { user?.image
            ? <ProfileImageWithFallback
              className='inline-block h-11 w-11 rounded-full bg-slate-400'
              src={user.image}
              alt='You'
            />
            : <UserIcon className='h-6 w-6' />
          }
        <ChevronDownIcon
          className="hidden absolute -bottom-1 left-0 right-0 sm:flex justify-center w-full h-4 text-gray-400 lg:block"
          aria-hidden="true"
        />
        <span className="sr-only">Open user menu</span>
        {/* <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
          <span className="sr-only">Open user menu for </span>
        </span> */}
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        { user && <>
          <Menu.Item>
            {({ active }) => (
              <a
                href={`/app/user/${user.id}`}
                className={active ? styles.primaryMenuItemActive : styles.primaryMenuItem}
              >
                { screenName
                  ? <div>
                      <div className={styles.loggedInAs}>Logged in as</div>
                      <div className={styles.userName}>@{ screenName }</div>
                    </div>
                  : <div>Your Profile</div>
                }
              </a>
            )}
          </Menu.Item>
          {/* <Menu.Item>
            {({ active }) => (
              <a
                href={`/app/user/${user.id}/settings`}
                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
              >
                Settings
              </a>
            )}
          </Menu.Item> */}
          <Menu.Item>
            {({ active }) => (
              <Clickable
                onClick={() => { handleSignOut() }}
                className={classNames(
                  active
                    ? 'bg-gray-100'
                    : '',
                  'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                )}
              >
                Logout
              </Clickable>
            )}
          </Menu.Item>
        </>}
        { !user && <Menu.Item>
          {({ active }) => (
            <Clickable
              onClick={() => { handleSignIn() }}
              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100')}
            >
              Sign in
            </Clickable>
          )}
        </Menu.Item> }

      </Menu.Items>
    </Transition>
  </Menu>
}

export default AccountMenu

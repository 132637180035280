import { User } from '@prisma/client'
import { RedirectTokenCommentData } from 'data/users/types'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import { post } from 'utilities/api'

export const useUser = () => {
  // console.log('useUser')
  const { data: user, error, mutate } = useSWR<User>('/user')
  // console.log({ user })

  return {
    user,
    isUserLoading: !error && !user,
    isUserError: error,
    refreshUser: mutate,
  }
}

export const useTrueUser = () => {
  // console.log('useUser')
  const { data: user, error, mutate } = useSWR<User>('/user/true')
  // console.log({ user })

  return {
    user,
    isUserLoading: !error && !user,
    isUserError: error,
    refreshUser: mutate,
  }
}

export const useScreenName = () => {
  const { data: session } = useSession()
  const profile = session?.profile as { screen_name?: string }
  const screenName = profile?.screen_name
  return screenName
}

export const makeRedirectTokenForUser = async (hostUrl: string, commentData?: RedirectTokenCommentData) => {
  console.log('GET REDIRECT TOKEN')
  const tokenResult = await post('/user/redirect/token', {
    hostUrl,
    commentData,
  })
  // console.log({ tokenResult })
  return tokenResult.data
}


export const useValidatedRedirectToken = (redirectToken?: string) => {
  // console.log('USE VALIDATED REDIRECT TOKEN', { redirectToken })
  const { data: validatedResult, error } = useSWR( redirectToken ? `/user/redirect/token/${redirectToken}` : null)
  console.log({ validatedResult })
  return {
    validatedResult,
    isValidatedResultLoading: !error && !validatedResult,
    isValidatedResultError: error,
  }
}

export const useCsrfTokenForAuth = () => {
  const { data: csrfToken, error } = useSWR('/auth/csrf')
  return {
    csrfToken,
    isCsrfTokenLoading: !error && !csrfToken,
    isCsrfTokenError: error,
  }
}

// // Using a form instead of below because we need the 302 to actually redirect the browser
// export const startTwitterAuth = (csrfToken: string) => {
//   console.log('Start Twitter Auth', csrfToken)
//   post('/auth/signin/twitter', {
//     csrfToken,
//   }, {
//     headers: { 'content-type': 'application/x-www-form-urlencoded' },
//   })
// }

export const updateUserIsNotNewb = async () => {
  const isNewbResult = await post('/user/isNotNewb')
  // console.log({ isNewbResult })
  return isNewbResult.data
}

export const updateUserIsAdminPanelExpanded = async (isAdminPanelExpanded: boolean) => {
  const isAdminPanelExpandedResult = await post('/user', {
    isAdminPanelExpanded,
  })
  // console.log({ isAdminPanelExpandedResult })
  return isAdminPanelExpandedResult.data
}

export const updateUser = async (emailAddress: string) => {
  const updatedUserResult = await post('/user', {
    emailAddress,
  })
  // console.log({ updatedUserResult })
  return updatedUserResult.data
}

export const verifyUserEmailAddress = async (confirmationCode: string) => {
  const isVerified = await post('/user/verification', {
    confirmationCode,
  })
  console.log({ isVerified })
  return isVerified
}

import { noop } from 'lodash'

type Props = {
  className: string,
  src?: string,
  alt: string,
}

const ProfileImageWithFallback = ({ className, src, alt }: Props) =>
  <img
    className={className}
    src={src}
    alt={alt}
    onError={({ currentTarget }) => {
      const fallbackUrl = 'https://useblotter.com/static/images/fallbackUserProfileImage.svg'
      currentTarget.onerror = noop
      if (currentTarget.src !== fallbackUrl) {
        currentTarget.src=fallbackUrl
      }
    }}
  />

export default ProfileImageWithFallback

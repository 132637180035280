import { useHost } from 'data/hosts'
import { Host } from 'data/hosts/types'
import { useEffect, useState } from 'react'
import { post } from 'utilities/api'
import { getHostOrigin } from 'utilities/url'
type Props = {
  hostId: string,
  isListeningOnly?: boolean,
}

// let isUrlRequested = false
const sendReadyForUrl = (host: Host) => {
  // if (!isUrlRequested) {
    // isUrlRequested = true
    const origin = getHostOrigin(host)
    // console.log('SEND READY FOR URL', origin)
    window?.parent?.postMessage({
      blotter: {
        type: 'urlReady',
      }
    }, origin)
  // }
}

let storedParentUrl = ''

const useParentUrlRecording = ({ hostId, isListeningOnly = false }: Props) => {
  const [parentUrl, setParentUrl] = useState('')
  const [isPageViewRecorded, setIsPageViewRecorded] = useState(false)
  const { host } = useHost(hostId)
  useEffect(() => {
    const eventHandler = (ev: MessageEvent) => {
      if (ev.data.blotter && ev.data.blotter.type === 'urlFromHost') {
        // console.log('Received URL')
        const url = ev.data.blotter.url
        const title = ev.data.blotter.title
        const description = ev.data.blotter.description
        // console.log({ url, hostId, description })
        setParentUrl(url)
        storedParentUrl = url
        if (hostId && !isPageViewRecorded && !isListeningOnly) {
          // console.log('USE PARENT URL RECORDING', hostId, { isListeningOnly })
          setIsPageViewRecorded(true)
          post('/pageviews', {
            url,
            hostId,
            title,
            description,
          })
        }
      }
    }
    window.addEventListener('message', eventHandler)

    return () => {
      console.log('Remove listener')
      window.removeEventListener('message', eventHandler)
    }
  }, [hostId, isPageViewRecorded, isListeningOnly])

  useEffect(() => {
    if (host) {
      sendReadyForUrl(host)
    }
  }, [host])

  return parentUrl
}

// if you're in the embedded comments page, useParentUrlRecording has already been called so useParentUrl will get you the current page url
export const useParentUrl = () => {
  return storedParentUrl
}

export default useParentUrlRecording

import { Host } from 'data/hosts/types'
import getConfig from 'next/config'
import { isServer } from 'utilities/window'

export const getHostFromUrl = (urlString: string) => {
  const url = new URL(urlString)
  return url.host
}

export const getUrlDomain = (urlString: string) => {
  const host = getHostFromUrl(urlString)
  return `https://${host}`
}

export const getPathFromUrl = (urlString: string) => {
  const url = new URL(urlString)
  return url.pathname
}

export const isCurrentPath = (pathString: string) => {
  if (isServer()) {
    return false
  }
  const currentPathName = window?.location?.pathname
  // const itemPath = getNavigationPath(item, hostId)
  console.log('iCP', currentPathName, pathString, currentPathName === pathString)
  return currentPathName === pathString
}


const blotterIdSeparator = ':::'
export const getBlotterUrlFromHostAndPath = (host: string, path: string) => `${host}${blotterIdSeparator}${encodeURIComponent(path)}`

export const getHostAndPathFromBlotterUrl = (blotterId: string) => {
  const [blogSlug, encodedUrlPath] = blotterId.split(blotterIdSeparator)
  return [blogSlug, decodeURIComponent(encodedUrlPath)]
}

export const getBlotterUrlFromHostAndUrl = (host: string, url: string) => getBlotterUrlFromHostAndPath(host, getPathFromUrl(url))

export const isUrl = (urlString: string) => {
  let url

  if (!(/[\S]+\.[\S]{2,}/.test(urlString))) {
    return false
  }

  try {
    url = new URL(`https://${urlString}`)
  } catch (_) {
    return false
  }

  return url.protocol === 'https:'
}

const { publicRuntimeConfig } = getConfig()
export const isDev = publicRuntimeConfig.env !== 'production'

export const getHostOrigin = (host: Host) => {
  const origin =
    isDev
      // ? 'https://useblotter.test:9104'
      ? 'https://imperfectbuilder.test:9105'
      : `https://${host.origin}`
  return origin
}
